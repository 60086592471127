<template>
  <div>
    <v-card class="centered-panel abi-panel mb-8">
      <div class="top-padding-without-list"></div>
      <v-subheader class="justify-space-between">
        <div>Allgemeine Umfrage, Fehlende Kontaktdaten</div>
        <div>26.02.2023</div>
      </v-subheader>
      <v-divider> </v-divider>

      <div class="link">
          <a href="https://docs.google.com/forms/d/e/1FAIpQLSfoADTsQJthYhTeb8P34QtSwVJjMtVw3T43LnCXkm_trn3T4g/viewform?usp=pp_url&entry.1745178045=Mai+2024&entry.1745178045=Juni+2024&entry.1745178045=Juli+2024&entry.1745178045=August+2024&entry.1745178045=September+2024&entry.1745178045=Oktober+2024&entry.1745178045=November+2024&entry.909481105=keine+Beschr%C3%A4nkung">
          Allgemeine Umfrage zum Jahrgangstreffen 
          </a>
        </div>
      <div class="px-6 pb-2">
        <p>Liebe ehemalige Mitschüler*innen,</p>

        <p>wir haben viele Rückmeldungen bekommen und das Interesse an einem Jahrgangstreffen ist groß. Das Orga-Team hat seine Arbeit aufgenommen und als erstes wollen wir euch noch ein paar Fragen zum Jahrgangstreffen stellen. Wie, wann, wo das Treffen stattfindet, wollen wir nicht alleine entschieden. Deswegen haben wir eine kurze Umfrage erstellt.</p>
        <p>Bitte gebt bis zum 17.03. eure Stimmen ab. </p>

        <p>Folgende Personen haben noch keine Kontaktdaten hinterlegt. Wenn ihr Kontakt zu jemandem von der Liste habt, gebt den Link und diese Information bitte weiter. </p>
        <p>(Liste aktualisiert am 08.03.) Pauline Bjarsch, Annika Böttcher, Konrad Braatz, Julie Gambia, Doro H, Bendine Heise, Sophie Leschik, Yara Levermann, Jannis Machel, Fiona Merian, Steffen Voigt, Jakob Wandelt, Benjamin Petsch </p>

        <p>Viele Grüße vom Orga-Team</p>
      </div>
    </v-card>

    <v-card class="centered-panel">
      <div class="top-padding-without-list"></div>
      <v-subheader class="justify-space-between">
        <div>Jahrgangstreffen 2024 - 10 Jahre Abi</div>
        <div>22.12.2022</div>
      </v-subheader>
      <v-divider> </v-divider>

      <div class="link abi-panel-content">
        <a href="https://docs.google.com/forms/d/e/1FAIpQLSdIEaUeOJV6Jl_a1dTD1aWLZcrMQF-vyw_FOjzl7Pr5GBI0cA/viewform?usp=pp_url&entry.1544975529=Ja">
        Hier geht es zur Eingabe der Kontaktdaten für das Jahrgangstreffen
        </a>
      </div>
    </v-card>
  </div>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'abi2014',
});
</script>

<style scoped>
  .link {
    margin: 20px 0 20px 0;
    padding: 0px 16px 0 16px;
    text-align: center;
    font-size: 25px;
  }
  .abi-panel-content {
    min-height: 100px;
  }
</style>